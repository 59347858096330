<template>
	<v-footer
		dark
		color="darkgrey"
		:height="$vuetify.breakpoint.xs ? '220px' : '140px'"
	>
		<v-row class="flex-column flex-nowrap">
			<v-col cols="12">
				<p>
					<router-link to="/about">
						{{ $t('about') }}
					</router-link>
				</p>
				<p>
					<router-link to="/contacts">
						{{ $t('contacts') }}
					</router-link>
				</p>
			</v-col>
		</v-row>

		<v-row class="flex-column flex-nowrap">
			<v-col cols="12">
				<p>
					<router-link to="/termsandconditionsbg">
						{{ $t('termsAndConditions') }}
					</router-link>
				</p>
				<p>
					<router-link to="/privacypolicybg">
						{{ $t('gdprPolicy') }}
					</router-link>
				</p>
			</v-col>
		</v-row>
		<v-spacer></v-spacer>

		<v-row class="text-sm-center text-md-right">
			<v-col>
				<a
					href="https://www.facebook.com/groups/2701031893490258/"
					target="_blanc"
				>
					<v-icon class="mr-3">
						mdi-facebook
					</v-icon>
				</a>

				<span>PetVet Manager &copy; {{ new Date().getFullYear() }}</span>
			</v-col>
		</v-row>
	</v-footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
p {
	margin-bottom: 8px !important;
}
a {
	color: #fff !important;

	.dev-name {
		color: orange;
		font-weight: bold;
	}
}
.contacts {
	margin-right: 10px;
	margin-left: 20px;
}
</style>
