import axios from 'axios';

const url = process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1';

const state = {
	pets: [],
	pet: null,
};

const actions = {
	async getPets({ commit }, userId) {
		const res = await axios.get(`${url}/pets/user/${userId}`);

		commit('SET_PETS', res.data.data);
		return res;
	},

	async getPet({ commit }, petId) {
		const res = await axios.get(`${url}/pets/${petId}`);

		commit('SET_PET', res.data.data);
		return res;
	},

	async createPet({ commit }, pet) {
		const res = await axios.post(`${url}/pets`, pet);

		commit('CREATE_PET', res.data.data);
		return res;
	},

	async editPet({ commit }, pet) {
		let res;

		if (pet instanceof FormData) {
			const id = pet.get('id');
			res = await axios.put(`${url}/pets/${id}`, pet, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
		} else {
			res = await axios.put(`${url}/pets/${pet._id}`, pet);
		}

		commit('EDIT_PET', res.data.data);
		return res;
	},

	async deletePet({ commit }, petId) {
		const res = await axios.delete(`${url}/pets/${petId}`);

		commit('DELETE_PET', petId);
		return res;
	},
};

const mutations = {
	SET_PETS: (state, pets) => (state.pets = pets),

	SET_PET: (state, pet) => (state.pet = pet),

	CREATE_PET: (state, pet) => state.pets.push(pet),

	EDIT_PET: (state, pet) => {
		state.pets.forEach(element => {
			if (element._id === pet._id) {
				element = pet;
			}
		});
		state.pet = pet;
	},

	DELETE_PET: (state, petId) => {
		state.pets = state.pets.filter(pet => pet._id !== petId);
		state.pet = null;
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
