import Vuex from 'vuex';
import Vue from 'vue';
import auth from './modules/auth';
import pets from './modules/pets';

// Load Vuex
Vue.use(Vuex);

// Create Store
export default new Vuex.Store({
	modules: {
		auth,
		pets,
	},
});
