import Vue from 'vue';
import VueRouter from 'vue-router';
import authStore from '../store/modules/auth';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
	// General
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/about',
		name: 'About',
		component: () => import('@/views/About'),
	},
	{
		path: '/contacts',
		name: 'Contacts',
		component: () => import('@/views/Contacts'),
	},

	// Auth
	{
		path: '/registered/:id',
		name: 'Registered',
		component: () => import('@/views/auth/Registered'),
	},
	{
		path: '/verify/:verificationtoken',
		name: 'Verify',
		component: () => import('@/views/auth/VerifyEmail'),
	},
	{
		path: '/resendvalidationemail',
		name: 'Resend-validation-email',
		component: () => import('@/views/auth/ResendValidationEmail'),
	},
	{
		path: '/resetpassword/:resettoken',
		name: 'Reset-password',
		component: () => import('@/views/auth/PasswordReset.vue'),
	},

	// Pets
	{
		path: '/pets',
		name: 'Pets',

		component: () => import('@/views/pets/Pets.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/add-pet',
		name: 'AddPet',

		component: () => import('@/views/pets/CreatePet.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/pet/:slug',
		name: 'Pet',
		props: true,
		component: () => import('@/views/pets/Pet.vue'),
		meta: { requiresAuth: true },
	},

	// Blog
	{
		path: '/blog',
		name: 'blog',
		component: () => import('@/views/blog/Articles'),
	},
	{
		path: '/blog/:slug',
		name: 'Article',

		component: () => import('@/views/blog/Article.vue'),
		// meta: { requiresAuth: true },
	},

	// Walk Posts
	{
		path: '/walkposts',
		name: 'WalkPosts',
		component: () => import('@/views/walk_posts/WalkPosts.vue'),
	},
	{
		path: '/walkposts/create-edit',
		name: 'WalkPostCreateEdit',

		component: () => import('@/views/walk_posts/WalkPostCreateEdit.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/walkposts/:id',
		name: 'WalkPost',

		component: () => import('@/views/walk_posts/WalkPost.vue'),
		// meta: { requiresAuth: true },
	},

	// Terms and Policies
	{
		path: '/terms-and-conditions',
		name: 'Terms',

		component: () => import('@/views/gdpr/TermsAndConditions.vue'),
	},
	{
		path: '/termsandconditions',
		name: 'terms-and-conditions',
		component: () => import('@/views/gdpr/TermsAndConditions'),
	},
	{
		path: '/termsandconditionsbg',
		name: 'terms-and-conditions-bg',
		component: () => import('@/views/gdpr/TermsAndConditionsBG'),
	},
	{
		path: '/privacypolicy',
		name: 'privacy-policy',
		component: () => import('@/views/gdpr/PrivacyPolicy'),
	},
	{
		path: '/privacypolicybg',
		name: 'privacy-policy-bg',
		component: () => import('@/views/gdpr/PrivacyPolicyBG'),
	},

	// Account Settings
	{
		path: '/user-settings',
		name: 'usersettings',
		component: () => import('@/views/UserSettings'),
		meta: { requiresAuth: true },
	},

	// Admin
	{
		path: '/admin/article/create',
		name: 'ArticleCreateEdit',

		component: () => import('@/views/blog/ArticleCreateEdit.vue'),
		meta: { requiresAuth: true, requiresAdmin: true },
	},

	// 404
	{
		path: '/*',
		name: '404',
		component: () => import('@/views/404'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.meta.requiresAuth && !authStore.state.user) {
		next({
			name: 'Login',
		});
	} else {
		next();
	}
});
router.beforeEach((to, from, next) => {
	if (to.meta.requiresAdmin && authStore.state.user.role !== 'admin') {
		next({
			name: '404',
		});
	} else {
		next();
	}
});

export default router;
