import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueGtag from 'vue-gtag';
import VueCookies from 'vue-cookies';
import i18n from './i18n';
import axios from 'axios';
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';
import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
import vuetify from './plugins/vuetify';
import wysiwyg from 'vue-wysiwyg';
import authHeader from './services/auth-service';

Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);

// Load Google UA Analytics
Vue.use(
	VueGtag,
	{
		config: { id: 'UA-155286192-2' },
	},
	router,
);

// Load vue-cookie
Vue.use(VueCookies);
Vue.$cookies.config('30d');

// Load Wysiwyg editor
Vue.use(wysiwyg);

// Use interceptors to check always for latest version of token
axios.interceptors.request.use(
	function(config) {
		// Do something before request is sent
		config.headers['Authorization'] = authHeader();
		return config;
	},
	function(error) {
		// Do something with request error
		return Promise.reject(error);
	},
);

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	i18n,
	vuetify,
	render: h => h(App),
	mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}).$mount('#app');
