import axios from 'axios';
import cookies from 'vue-cookies';
import authHeader from '../../services/auth-service';

const url = process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1';

const state = {
	user: JSON.parse(localStorage.getItem('user')) || null,
	accessToken: cookies.get('access_token') || null,
};

const getters = {
	isLoggedIn: state => !!state.accessToken,
};

const actions = {
	async login({ commit }, user) {
		const res = await axios.post(`${url}/auth/login`, user);

		cookies.set('access_token', res.data.token);
		localStorage.setItem('user', JSON.stringify(res.data.user));
		axios.defaults.headers.common['Authorization'] = authHeader();

		commit('LOGIN', res.data);

		return res;
	},

	async deleteUser({ commit }, id) {
		await axios.delete(`${url}/auth/users/${id}`);

		commit('DELETE_USER');
	},

	async logout({ commit }) {
		await axios.get(`${url}/auth/logout`);

		cookies.remove('access_token');
		localStorage.clear();
		delete axios.defaults.headers.common['Authorization'];

		commit('LOGOUT_USER');
	},
};

const mutations = {
	LOGIN: (state, data) => {
		state.user = data.user;
		state.accessToken = data.token;
	},

	LOGOUT_USER: state => {
		state.user = null;
		state.accessToken = null;
	},

	// DELETE_USER: state => (state.loggedUser = {}),
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
