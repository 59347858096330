<template>
	<v-app v-cloak>
		<Header />
		<v-main>
			<transition name="fade" mode="out-in">
				<router-view />
			</transition>
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
import { mapActions } from 'vuex';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';

export default {
	name: 'App',
	components: {
		Header,
		Footer,
	},

	methods: {
		...mapActions('auth', ['logout']),
	},
	created() {
		// Interceptor to listen for 401 and call logout method
		this.$http.interceptors.response.use(
			result => {
				return result;
			},
			async error => {
				if (error.response.status === 401) {
					try {
						await this.logout();

						if (this.$route.name === 'Home') {
							return;
						} else {
							this.$router.push('/');
						}
					} catch (error) {
						console.error(error);
					}
				} else {
					return Promise.reject(error);
				}
			},
		);
	},
};
</script>
<style lang="scss">
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

#app {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	line-height: 1.6;
	background: #f8f8f8;

	[v-cloak] {
		display: none;
	}

	a {
		text-decoration: none;
	}

	.v-btn--text {
		&:hover {
			background: transparent !important;
			border-bottom: 1px solid green;
			border-radius: 0;
		}
	}

	.container {
		padding: 2rem 2rem !important;

		h3 {
			padding: 1rem 0;
		}
	}

	.text-shadow {
		text-shadow: 2px 3px 4px #333;
	}
}
</style>
