<template>
	<div>
		<AppWelcome />

		<AppFeatures />

		<AppBlog />

		<AppWalks />

		<AppStats />

		<AppAboutUs />

		<AppContacts />
	</div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex';

export default {
	name: 'Home',
	components: {
		AppWelcome: () => import('@/components/sections/AppWelcome'),
		AppFeatures: () => import('@/components/sections/AppFeatures'),
		AppStats: () => import('@/components/sections/AppStats'),
		AppContacts: () => import('@/components/sections/AppContacts'),
		AppBlog: () => import('@/components/sections/AppBlog'),
		AppAboutUs: () => import('@/components/sections/AppAboutUs'),
		AppWalks: () => import('@/components/sections/AppWalks'),
	},

	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState('auth', ['user']),
		...mapGetters('auth', ['isLoggedIn']),
	},
};
</script>
<style lang="scss" scoped>
.image {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}
</style>
