<template>
	<div>
		<v-app-bar app color="primary" dark height="100">
			<v-avatar class="mr-3" color="primary" size="90">
				<v-img
					contain
					max-height="90%"
					max-width="200"
					class="logo"
					src="@/assets/logo.png"
					@click="goHome"
				></v-img>
			</v-avatar>

			<v-toolbar-title class="font-weight-black headline  logo" @click="goHome">
				PetVet Manager
			</v-toolbar-title>

			<!-- Desktop Menu -->
			<v-spacer></v-spacer>
			<div class="hidden-sm-and-down">
				<v-btn v-if="!user" text small @click="dialogLogin = true" class="mr-2">
					{{ $t('login') }}
				</v-btn>

				<v-btn
					v-if="!user"
					text
					small
					@click="dialogRegister = true"
					class="mr-2"
				>
					{{ $t('register') }}
				</v-btn>

				<v-btn v-if="user" text small to="/pets" class="mr-2">
					{{ $t('userProfile') }}
				</v-btn>

				<v-btn text small to="/walkposts" class="mr-2">
					{{ $t('walkPosts') }}
				</v-btn>

				<v-btn text small to="/blog" class="mr-2">
					{{ $t('blog') }}
				</v-btn>

				<v-btn v-if="user" text small to="/user-settings" class="mr-2">
					{{ $t('settings') }}
				</v-btn>

				<v-btn v-if="user" text small class="mr-2" @click="onLogout">
					{{ $t('logout') }}
				</v-btn>
			</div>

			<!-- Mobile Menu -->
			<v-menu class="hidden-md-and-up">
				<template v-slot:activator="{ on }">
					<v-btn class="hidden-md-and-up" icon v-on="on">
						<v-icon class="mobile-menu-icon">mdi-menu</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item v-if="!user" @click="dialogLogin = true">
						<v-list-item-title>{{ $t('login') }}</v-list-item-title>
					</v-list-item>

					<v-list-item v-if="!user" @click="dialogRegister = true">
						<v-list-item-title>{{ $t('register') }}</v-list-item-title>
					</v-list-item>

					<v-list-item v-if="user" to="/pets">
						<v-list-item-title>{{ $t('userProfile') }}</v-list-item-title>
					</v-list-item>

					<v-list-item to="/walkposts">
						<v-list-item-title>{{ $t('walkPosts') }}</v-list-item-title>
					</v-list-item>

					<v-list-item to="/blog">
						<v-list-item-title>{{ $t('blog') }}</v-list-item-title>
					</v-list-item>

					<v-list-item v-if="user" to="/user-settings">
						<v-list-item-title>{{ $t('settings') }}</v-list-item-title>
					</v-list-item>

					<v-list-item v-if="user" to="/logout" @click="onLogout">
						<v-list-item-title>{{ $t('logout') }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<LanguageBar />
		<Login
			:dialogLogin="dialogLogin"
			@close-login="dialogLogin = false"
			@not-registered="closeLoginOpenRegister"
			@forgot-pass="closeLoginOpenForgotPass"
		/>
		<Register
			:dialogRegister="dialogRegister"
			@close-register="dialogRegister = false"
			@already-registered="closeRegisterOpenLogin"
		/>
		<ForgotPassword
			:dialogForgotPass="dialogForgotPass"
			@close-forgot="dialogForgotPass = false"
		/>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
	name: 'Header',

	components: {
		LanguageBar: () => import('@/components/layouts/LanguageBar'),
		Login: () => import('@/components/Login'),
		Register: () => import('@/components/Register'),
		ForgotPassword: () => import('@/components/ForgotPassword'),
	},
	data() {
		return {
			dialogLogin: false,
			dialogRegister: false,
			dialogForgotPass: false,
		};
	},
	computed: {
		...mapState('auth', ['user']),
	},
	methods: {
		...mapActions('auth', ['logout']),
		goHome() {
			if (this.$route.name === 'Home') {
				return;
			} else {
				this.$router.push('/');
			}
		},
		closeLoginOpenRegister() {
			this.dialogLogin = false;
			this.dialogRegister = true;
		},
		closeRegisterOpenLogin() {
			this.dialogRegister = false;
			this.dialogLogin = true;
		},
		closeLoginOpenForgotPass() {
			this.dialogLogin = false;
			this.dialogForgotPass = true;
		},
		async onLogout() {
			try {
				await this.logout();

				if (this.$route.name === 'Home') {
					return;
				} else {
					this.$router.push('/');
				}
			} catch (error) {
				console.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.logo {
	cursor: pointer;
}
</style>
